import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';

import { getRouteUrl } from '@utils/urls';

import { GOODIES_DETAILS } from '@common/routes';
import { BUTTON_VARIANTS, ButtonLink } from '@common/components/Button';
import LazyImg from '@common/components/LazyImg';
import PriceText from '@common/components/PriceText';

import {
  ActiveCardWrapper,
  ActiveCardContentWrapper,
  ActiveCardDescription,
  ActiveCardTitle,
} from './ActiveCard.styled';

const ActiveCard = ({
  image,
  title,
  description,
  isInvalid,
  templateId,
  slug,
  isAnimationPlaying,
  setIsAnimationPlaying,
}) => {
  const { locale } = useRouter();
  const { t } = useTranslation();

  useEffect(() => {
    if (setIsAnimationPlaying && isAnimationPlaying) {
      setIsAnimationPlaying(false);
    }
  }, [isAnimationPlaying, setIsAnimationPlaying]);

  const linkHref = {
    pathname: GOODIES_DETAILS,
    query: {
      id: templateId,
      slug,
    },
  };
  const linkAs = getRouteUrl(locale, GOODIES_DETAILS, {
    id: templateId,
    slug,
  });

  return (
    <ActiveCardWrapper isAnimationPlaying={isAnimationPlaying}>
      {image?.url && (
        <Link
          legacyBehavior
          href={linkHref}
          as={linkAs}
          passHref
        >
          <a>
            <LazyImg
              src={image?.url}
              alt={image?.altText || image?.title || ''}
              breakpoints={[700]}
              aspectRatios={['13.5:9']}
            />
          </a>
        </Link>
      )}
      <ActiveCardContentWrapper>
        <ActiveCardTitle>
          <PriceText>{title}</PriceText>
        </ActiveCardTitle>
        <ActiveCardDescription>
          <PriceText>{description}</PriceText>
        </ActiveCardDescription>
        {!isInvalid && (
          <Link
            legacyBehavior
            href={linkHref}
            as={linkAs}
            passHref
          >
            <ButtonLink variant={BUTTON_VARIANTS.LEVEL_2_GREY_20_SMALL}>
              {t('goodie.moreInfo', 'Weitere Infos')}
            </ButtonLink>
          </Link>
        )}
      </ActiveCardContentWrapper>
    </ActiveCardWrapper>
  );
};

ActiveCard.propTypes = {
  image: PropTypes.shape({
    url: PropTypes.string,
    altText: PropTypes.string,
    title: PropTypes.string,
  }),
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  isInvalid: PropTypes.bool,
  slug: PropTypes.string,
  templateId: PropTypes.string,
  isAnimationPlaying: PropTypes.bool,
  setIsAnimationPlaying: PropTypes.func,
};

ActiveCard.defaultProps = {
  templateId: undefined,
  isInvalid: undefined,
  isAnimationPlaying: undefined,
  setIsAnimationPlaying: undefined,
  image: undefined,
  slug: undefined,
  description: '',
};

export default ActiveCard;
